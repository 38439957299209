import { render, staticRenderFns } from "./payments.vue?vue&type=template&id=41adfda8&scoped=true&"
import script from "./payments.vue?vue&type=script&lang=js&"
export * from "./payments.vue?vue&type=script&lang=js&"
import style0 from "./payments.vue?vue&type=style&index=0&id=41adfda8&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "41adfda8",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VTextField})


/* hot reload */
if (module.hot) {
  var api = require("E:\\qikecheng_new\\allnet_crm\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('41adfda8')) {
      api.createRecord('41adfda8', component.options)
    } else {
      api.reload('41adfda8', component.options)
    }
    module.hot.accept("./payments.vue?vue&type=template&id=41adfda8&scoped=true&", function () {
      api.rerender('41adfda8', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/order/after-sale-nc/detail/components/payments.vue"
export default component.exports